<template>
  <v-card flat class="ma-0 d-flex flex-column" style="height:100%" :disabled="loading_messages">
    <v-card-title class="primary white--text rounded-0 d-flex align-start py-1">
      <span class="text-subtitle-1 name">{{ selected_private_user.full_name}}</span>
      <v-spacer></v-spacer>
      <v-tooltip
        bottom
        v-if="
          selected_private_user.attendee_id != myself.attendee_id &&
            Number(selected_private_user.online_status) == 1 &&
            selected_private_user.call_status === 'available'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :to="`/call/video?joineeId=${selected_private_user.attendee_id}`"
            class="mr-4"
            v-bind="attrs"
            v-on="on"
            small
          >
            <v-icon color="white">mdi-phone</v-icon>
          </v-btn>
        </template>
        <span>Make Video Call</span>
      </v-tooltip>
      <v-btn small color="red" dark depressed @click="removeCurrentUser">
        <span>Leave chat</span>
        <v-icon right small>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      class="chat-area d-flex flex-column-reverse grey lighten-4"
    >
      <v-card v-for="message in messages" :key="message.attendee_msg_id" flat class="grey lighten-4">
        <v-list-item
          :key="message.attendee_msg_id"
          v-if="message.send_by != myself.attendee_id"
          class="pa-0"
        >
          <v-list-item-avatar class="align-self-start mr-2 elevation-3">
            <v-avatar size="40">
              <v-img :src="selected_private_user.profile_pic" :lazy-src="FRONT_ASSETS + 'placeholder.png'"></v-img>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content class="received-message">
            <v-card color="success" class="flex-none">
              <v-card-text class="white--text pa-2 d-flex flex-column">
                <span class="text-caption name"
                  >{{ selected_private_user.first_name }}
                </span>
                <span class="text-body-2 chat-message">{{
                  message.message
                }}</span>
                <span class="text-caption font-italic align-self-end">{{
                  getTime(message.created_at)
                }}</span>
              </v-card-text>
            </v-card>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else :key="message.attendee_msg_id" class="pa-0">
          <v-list-item-content class="sent-message justify-end">
            <v-card color="primary" class="flex-none">
              <v-card-text class="white--text pa-2 d-flex flex-column">
                <span class="text-body-2 chat-message" :class="{'red--text font-italic' : message.failed}">{{
                  message.message
                }}</span>
                <span class="text-caption font-italic align-self-start">{{
                  getTime(message.created_at)
                }}</span>
              </v-card-text>
            </v-card>
          </v-list-item-content>
          <v-list-item-avatar class="align-self-start ml-2 elevation-3">
            <v-img :src="myself.profile_pic" :lazy-src="FRONT_ASSETS + 'placeholder.png'"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
      <v-btn text color="primary" @click="loadMoreMessage" v-if="isReadMore > 0">Load More</v-btn>
    </v-card-text>
    <v-card-actions class="chat-footer success lighten-5 pa-0">
      <v-row class="d-flex flex-row justify-space-between" no-gutters>
        <v-col cols="12">
          <v-textarea
            placeholder="Type your message"
            v-model="new_message"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="sendMessage()"
            @keydown.enter.shift.exact="newlineMessage"
            :loading="send_loading"
            hide-details=""
            rows="2"
            autofocus
            class="px-2"
            ref="textbox"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-actions>

  </v-card>
</template>

<script>
import axios from "@/helper/axios";
import { mapState,mapMutations} from "vuex";
import { getCallStatusCode } from "../../helper/attendee";

export default {
  name: "ChatBox",
  data() {
    return {
      messages: [],
      new_message: "",
      total_records : 0,
      send_loading: false,
      socket_messages: [],
      attendee_data : {},
      is_subscribed : false,
      loading_messages : false
    };
  },
  watch : {
    selected_private_user : function(){
      this.getMessages();
      this.subscribeToIncoming();
      this.$refs['textbox'].focus()
    },
    messages : function(){
      this.clearReadCount(this.selected_private_user.attendee_id)
    },
    online_status : function(val){
      if(!val){
        this.removeSubscription()
      } else {
         this.subscribeToIncoming();
      }
    }
  },
  computed: {
    ...mapState('notifications',['selected_private_user',"is_msg_expand","incoming_rintone"]),
    ...mapState("utils", ["online_status"]),
    myself() {
      return JSON.parse(localStorage.getItem("pinnacle_user"));
    },
    newMessageWithoutSpace() {
      return this.new_message.trim();
    },
    messageLength() {
      return this.newMessageWithoutSpace.length;
    },
    reverse_messages() {
      let messages = this.messages;
      let msgArray = [];
      for (let i = messages.length; i--; ) {
        msgArray.push(messages[i]);
      }
      return msgArray;
    },
    isReadMore(){
      return (this.total_records - this.messages.length)
    }
  },
  methods: {
    ...mapMutations('notifications',['clearReadCount','setPrivateChatUser','removeTemp','setChatPrivate','addNotification']),
    setSocketMessage(data) {
      if(this.selected_private_user &&
          ((data.send_by == this.myself.attendee_id && data.receive_by == this.selected_private_user.attendee_id) ||
          (data.receive_by == this.myself.attendee_id && data.send_by == this.selected_private_user.attendee_id))
        ){
          if(data.receive_by == this.myself.attendee_id && data.send_by == this.selected_private_user.attendee_id && this.is_msg_expand == 0){
            this.incoming_rintone.play()
            this.readMessage(data)
          }
        this.messages.unshift(data);
      }
    },
    getTime(time) {
      let msg_date = this.moment.utc(time);
      let today = this.moment(new Date());
      if (msg_date.isSame(today, "day")) {
        return this.moment.utc(time).local().format("hh:mm A");
      } else {
        return this.moment.utc(time).local().format("DD, MMM hh:mm A");
      }
    },
    newlineMessage() {
      this.new_message = `${this.new_message}`;
    },
    sendMessage() {
      if (this.newMessageWithoutSpace.length) {
        this.send_loading = true;
        let data = {
          message: this.newMessageWithoutSpace,
          receive_by: this.selected_private_user.attendee_id,
        };
        axios
          .post("/attendee/add_message", data)
          .then(() => {
            this.new_message = "";
            // this.messages.unshift(data.data.data)
            if(!this.online_status){
              this.addNotification({
                color : 'red',
                data : { message : 'it seems you are disconnected from socket.Please reload your page'}
              })
            }
          })
          .catch(() => {
            let failedMessage = {
              attendee_msg_id : Math.floor(Date.now() / 1000),
              send_by : this.myself.attendee_id,
              message : 'failed to send last message',
              created_at : new Date(),
              failed : true
            }
            this.messages.unshift(failedMessage)
          })
          .then(() => {
            this.send_loading = false;
          });
      }
    },
    getMessages() {
      this.loading_messages = true
      axios
        .get("/attendee/message_list", {
          params: {
            attendee_id: this.selected_private_user.attendee_id,
          },
        })
        .then((data) => {
          this.messages = data.data.data;
          this.total_records = data.data.totalRecord
          this.loading_messages = false
        }).catch(() => {
          this.messages = []
        }).then(() => {
          this.loading_messages = false
        });
    },
    loadMoreMessage(){
      axios
        .get("/attendee/message_list", {
          params: {
            attendee_id: this.selected_private_user.attendee_id,
            start_from : this.messages.length
          },
        })
        .then((data) => {
          this.messages.push(...data.data.data)
        });
    },
    readMessage(data){
      axios
        .post("/attendee/message_read", {
         message_id : data.attendee_msg_id
        })
    },
    subscribeToIncoming() {
      if(!this.is_subscribed){
        this.$ws.$on(
        `user:${this.myself.attendee_id}|new_message`,
          this.setSocketMessage
        );
        this.is_subscribed = true
      }
    },
    removeSubscription(){
      this.$ws.$off(`user:${this.myself.attendee_id}|new_message`);
      this.is_subscribed = false
    },
    removeCurrentUser(){
      this.removeTemp(this.selected_private_user)
      this.setPrivateChatUser(null)
      this.setChatPrivate(false)
    },
    handleAttendeeStatusEvents(data) {
      if (data.attendee_id && Array.isArray(this.attendee_list)) {
        const attendeeIndex = this.attendee_list.findIndex(attendee => {
          return attendee.attendee_id == Number(data.attendee_id);
        })

        if (attendeeIndex != -1) {
          const tempAttendees = [...this.attendee_list];
          tempAttendees[attendeeIndex].call_status = getCallStatusCode(data.call_status);
          tempAttendees[attendeeIndex].online_status = Number(data.online_status);
          this.attendee_list = tempAttendees;
        }
      }
    },
    subscribeToChannels() {
      let subscription = this.$ws.socket.getSubscription("attendee_status");

      if (!subscription) {
        subscription = this.$ws.subscribe("attendee_status");

        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      } else {
        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      }
    },
    unsubscribeFromChannels() {
      this.$ws.$off("attendee_status", this.handleAttendeeStatusEvents);
    },
  },
  mounted: function() {
    this.subscribeToChannels();
    this.getMessages();
    this.subscribeToIncoming();
  },
  beforeDestroy() {
    this.unsubscribeFromChannels()
  },
};
</script>

<style scoped>
.flex-none {
  flex: unset;
}
.chat-area {
  overflow-y: auto;
  min-height: 350px;
  max-height: 350px;
}
.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 40px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}
.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 40px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #80bc40 transparent transparent transparent;
}
</style>
