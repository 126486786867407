<template>
  <v-app>
      <router-view />
  </v-app>
</template>

<script>

export default {
  props: {
    source: String,
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chat-message {
  white-space: pre-wrap;
}
.w-100{
  width: 100%;
}
</style>
