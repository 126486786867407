<template>
<div>
  <v-card width="450" :loading="loading" :disabled="loading" class="mt-5">
    <v-card-title>
      <p class="text-justify text-h5 font-weight-light mb-0">Register yourself for the 2023 Pinnacle Best Practices  Symposium</p>
    </v-card-title>
    <v-card-text>
      <v-alert
        dense
        text
        type="error"
        v-html="failed_message"
        v-if="failed_message"
      >
      </v-alert>
      <v-form>
        <v-row dense>
          <v-col class="">
            <v-text-field
              label="First name"
              v-model.trim="form_data.first_name"
              dense
              outlined
              :error-messages="form_error.first_name"
              :hide-details="!form_error.first_name"
              placeholder=" "
              autofocus
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Last name"
              v-model.trim="form_data.last_name"
              dense
              outlined
              :error-messages="form_error.last_name"
              :hide-details="!form_error.last_name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-file-input
              v-model="form_data.profile_pic"
              accept="image/png,image/jpeg,image/jpg"
              label="Profile Pic"
              dense
              outlined
              prepend-icon="mdi-camera"
              :error-messages="form_error.profile_pic"
              :hide-details="!form_error.profile_pic"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              label="Email"
              v-model.trim="form_data.email"
              dense
              outlined
              :error-messages="form_error.email"
              :hide-details="!form_error.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model.trim="form_data.phone"
              dense
              outlined
              :error-messages="form_error.phone"
              :hide-details="!form_error.phone"
            >
              <template slot="label">
                <span>Phone (optional)</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              label="Organization"
              v-model.trim="form_data.organization"
              dense
              outlined
              :error-messages="form_error.organization"
              :hide-details="!form_error.organization"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              label="Title"
              v-model.trim="form_data.title"
              dense
              outlined
              :error-messages="form_error.title"
              :hide-details="!form_error.title"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              label="Type"
              v-model.trim="form_data.type"
              dense
              outlined
              :error-messages="form_error.type"
              :hide-details="!form_error.type"
              :items="userTypes"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model.trim="form_data.linkedin_url"
              label="LinkedIn URL (optional)"
              dense
              outlined
              :error-messages="form_error.linkedin_url"
              :hide-details="!form_error.linkedin_url"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-0 pb-0">
      <div class="w-100">
        <v-btn
          color="secondary"
          class="float-left mx-4 mb-3"
          depressed
          to="/"
        >
          back</v-btn
        >
        <v-btn
          color="primary"
          class="float-right mx-4 mb-3"
          depressed
          :loading="loading"
          @click="register"
        >
          Sign up</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
  <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="is_success" persistent>
    <v-card color="primary">
      <v-card-text class="pt-4 text-center white--text">
        <v-icon size="50" color="white" class="align-center">mdi-check</v-icon>
        <div class="text-justify text-h5 font-weight-light">
          {{success_message}}
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          to="/"
        >Close</v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</div>
</template>

<script>
import axios from "@/helper/axios";
export default {
  name: "Registration",
  data() {
    return {
      form_error: {},
      form_data: {},
      password: null,
      loading: false,
      data: {},
      userTypes: [
        {
          text: "Attendee",
          value: "ATTENDEE",
        },
        {
          text: "Speaker",
          value: "S_ATTENDEE",
        },
      ],
      is_success : false,
      success_message: "",
      failed_message: "",
    };
  },
  methods: {
    register() {
      this.loading = true;
      this.form_error = {};
      this.success_message = "";
      this.failed_message = "";

      const formData = new FormData();
      
      formData.append("first_name", this.form_data.first_name || "")
      formData.append("last_name", this.form_data.last_name || "")
      formData.append("profile_pic", this.form_data.profile_pic || "")
      formData.append("email", this.form_data.email || "")
      formData.append("phone", this.form_data.phone || "")
      formData.append("organization", this.form_data.organization || "")
      formData.append("title", this.form_data.title || "")
      formData.append("type", this.form_data.type || "")
      formData.append("linkedin_url", this.form_data.linkedin_url || "")
      
      axios
        .post("/signup", formData)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            this.form_data = {};
            this.is_success = true
            this.success_message = response_data.message;
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              this.form_error[errors[key].field] = errors[key].message;
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status == 429) {
            this.failed_message = `Too many requests, please try again later!`;
          }
          this.loading = false;
        });
    },
  },
  mounted: function () {},
};
</script>
<style>
</style>