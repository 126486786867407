import axios from '../helper/axios'
const state = {
  incoming_rintone : new Audio(process.env.VUE_APP_ASSETS +'frontend/music/alert.mp3'),
  is_msg_expand : 1,
  infodesk_messages : [],
  unread_counter : 0,
  selected_chat_user : null,
  is_private_chat : false,
  private_messages : [],
  selected_private_user : null,
  quick_notifications : []
}
const getters = {
  existingPrivateMembers: state => {
    return state.private_messages
  },
  unreadTotalCount : state => {
    let totalUnread = 0
    state.private_messages.map((attendee) => {
      totalUnread += attendee.total_unread_msg
    })
    return totalUnread
  }
}
const actions = {
  getPrivateMessages({commit},initial = true) {
    axios.get('attendee/chat_member')
    .then((data) => {
      let messages = {
        data : data.data.data,
        initial : initial
      }
      commit('setInitialPrivateMessages',messages);
    })
  }
}
const mutations = {
  setInitialData(state,data){
    state.infodesk_messages = data
  },
  increaseCounter(state){
    state.unread_counter++
  },
  setChatUser(state,data) {
    state.selected_chat_user = data
  },
  setChatPrivate(state,data) {
    state.is_private_chat = data
  },
  setInitialPrivateMessages(state,data) {
    if(data.initial){
      state.private_messages = data.data
    }
    else {
      let finalAttendeeList = []
      data.data.map((newAttendee)=>{
        let foundAttendee = state.private_messages.filter((eAttendee) => {
          return eAttendee.attendee_id == newAttendee.attendee_id
        })

        if(foundAttendee.length == 1){
          if(foundAttendee[0].temp_removed && foundAttendee[0].temp_removed == true && newAttendee.total_unread_msg > 0){
            foundAttendee[0].temp_removed = false
          }
          finalAttendeeList.unshift(foundAttendee[0])
        } else {
          finalAttendeeList.unshift(newAttendee)
        }
      })
      state.private_messages = finalAttendeeList
    }
  },
  setTempPrivateMessage(state,data) {
    if(state.private_messages.length){
      let found = state.private_messages.filter((attendee) => {
        return attendee.attendee_id == data.attendee_id
      }).length
      if(found == 0) {
        state.private_messages.unshift(data)
      }
    } else {
      state.private_messages.unshift(data)
    }
  },
  setPrivateChatUser(state,data) {
    state.selected_private_user = data
  },
  setExpandBox(state,data) {
    state.is_msg_expand = data
  },
  addNotification(state,data){
    state.quick_notifications.push(data)
    state.incoming_rintone.play()
    if(!data.data.no_timeout){
      setTimeout(()=> {
        if(state.quick_notifications.length){
          state.quick_notifications.pop()
        }
      },7000)
    }
  },
  updateUnreadCounter(state,data){
    state.private_messages = state.private_messages.map((attendee) => {
      if(attendee.attendee_id == data.send_by){
        if(attendee.temp_removed && attendee.temp_removed == true){
          attendee.temp_removed = false
        }
        attendee.total_unread_msg++
      }
      return attendee
    })
  },
  clearReadCount(state,attendee_id) {
    state.private_messages = state.private_messages.map((attendee) => {
      if(attendee.attendee_id == attendee_id){
        attendee.total_unread_msg = 0
      }
      return attendee
    })
  },
  removeTemp(state,attendeeE) {
    state.private_messages = state.private_messages.map((attendee) => {
      if(attendee.attendee_id == attendeeE.attendee_id){
        attendee.temp_removed = true
      }
      return attendee
    })
  },
  setPrivateMessages(state, data) {
    state.private_messages = data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}