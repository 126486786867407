<template>
<VueDragResize :isActive="true" :y="x" :x="y" :w="width" :h="height" v-on:resizing="resize" v-on:dragging="resize" v-if="session_info">
  <v-card class=" blue-grey lighten-5 minimized-session" ref="video-card" :width="width" :height="height">
    <v-card-title class="pa-0 d-flex justify-end primary md-toolbar" ref="drag-start">
        <v-btn depressed small color="primary" :to="'/app/session/'+session_info.session_id">
          <v-icon>mdi-window-maximize</v-icon>
        </v-btn>
        <v-btn depressed small color="primary" @click="closeWindow">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
    </v-card-title>
    <v-card-text class="pa-0">
      <!-- <div style="position: absolute;top: 45%;left: 30%;z-index:1">
        <v-btn  v-if="!playing" color="success" fab  x-large @click="playing = true">
          <v-icon>mdi-play-circle-outline</v-icon>
        </v-btn>
      </div>             -->
      <video :width="width" :height="height-30" autoplay v-if="!session_info.is_live_meeting"
      :src="session_info.meeting_id_url+'#t='+startTime"
      ref="player"
      class="disable_click"
      >
        Your browser does not support the video tag.
      </video>
      <!-- <iframe
      :width="width"
      :height="height-30"
      :src="getYoutubeUrl(session_info.meeting_id_url)"
      frameborder="0"
      allowfullscreen
      v-if="!session_info.is_live_meeting"
      class="disable_click"
      ></iframe> -->
      <iframe
        :width="width"
        :height="height-30"
        :src="base_url+session_info.meeting_id_url"
        class="rounded-lg"
        allow="camera; microphone"
        v-else
        allowfullscreen
      ></iframe>
    </v-card-text>
  </v-card>
</VueDragResize>
</template>

<script>
import axios from "@/helper/axios";
import {mapState,mapActions} from 'vuex'
import VueDragResize from 'vue-drag-resize';
export default {
  name : 'MinimizedSession',
  components : {VueDragResize},
  data(){
    return {
      width : 350,
      height : 350,
      x : 220,
      y : 0,
      startTime : 0
    }
  },
  computed :{
    ...mapState('active_session',['session_info']),
    myself() {
      return JSON.parse(localStorage.getItem("flaacos_user"));
    },
    base_url(){
      return axios.defaults.baseURL + 'zoom-meeting?token='+this.myself.attendee_token+'&meetingNumber='
    },
  },
  methods :{
    ...mapActions('active_session',['removeSession']),
    isUpcoming(session) {
      let startTime = this.moment(session.start_time);
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return !currentTime.isBetween(startTime, endTime)
    },
    closeWindow(){
      let subscription = this.$ws.socket.getSubscription(
        `session:${this.session_info.session_id}`
      );
      if (subscription) {
        subscription.close()
      }
      this.$ws.socket.removeSubscription(`session:${this.session_info.session_id}`)
      this.removeSession()
    },
    resize: function (d) {
      this.width = d.width
      this.height = d.height
    },
    getYoutubeUrl(video_id){
      return 'https://www.youtube.com/embed/'+video_id+'?autoplay=1&controls=0&disablekb=0&loop=1&playlist='+video_id
    },
  },
  mounted : function(){
    if(this.isUpcoming(this.session_info)){
      this.closeWindow()
    }
    let  startTime = this.moment(this.session_info.start_time);
    let currentTime = this.moment();
    this.startTime = currentTime.diff(startTime, "seconds")
    this.x = this.$vuetify.breakpoint.height -380
    this.y = this.$vuetify.breakpoint.width - 380
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
.disable_click{
  pointer-events:none;
}
.minimized-session {
  z-index: 5;
  position: absolute;
}
</style>