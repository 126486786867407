var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"incoming-call-notification-wrapper"},[_c('button',{ref:"ringtoneBtn",staticClass:"d-none",attrs:{"id":"ringtoneBtn"},on:{"click":_vm.playAudio}}),_c('audio',{ref:"ringtone",attrs:{"src":require("@/assets/ringtone.mp3"),"loop":""}}),_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"50px"}},[_c('v-img',{attrs:{"src":_vm.incomingCallData.callerProfilePic
            ? (_vm.assetsBaseURL + "/uploads/" + (_vm.incomingCallData.callerProfilePic))
            : ("https://via.placeholder.com/100x100/dbebff/003a80?text=" + (_vm.incomingCallData.callerNameIntials)),"lazy-src":_vm.FRONT_ASSETS + 'placeholder.png'}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title",attrs:{"title":_vm.incomingCallData.callerName}},[_vm._v(_vm._s(_vm.incomingCallData.callerName))]),_c('v-list-item-subtitle',{staticClass:"subtitle"},[_vm._v("Incoming call")])],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex flex-row ml-5 mr-2"},[_c('v-tooltip',{attrs:{"bottom":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"dark":"","fab":"","depressed":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.$emit('call_accepted')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-phone ")])],1)]}}])},[_c('span',{staticClass:"black--text"},[_vm._v("Answer Call")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"dark":"","fab":"","depressed":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.$emit('call_rejected')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-phone-hangup ")])],1)]}}])},[_c('span',{staticClass:"black--text"},[_vm._v("Reject Call")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }