<template>
  <div class="incoming-call-notification-wrapper">
    <button @click="playAudio" id="ringtoneBtn" ref="ringtoneBtn" class="d-none"></button>
    <audio src="@/assets/ringtone.mp3" loop ref="ringtone"/>
    <v-list-item>
      <v-list-item-avatar size="50px">
        <v-img
          :src="
            incomingCallData.callerProfilePic
              ? `${assetsBaseURL}/uploads/${incomingCallData.callerProfilePic}`
              : `https://via.placeholder.com/100x100/dbebff/003a80?text=${incomingCallData.callerNameIntials}`
          "
          :lazy-src="FRONT_ASSETS + 'placeholder.png'"
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="title" :title="incomingCallData.callerName">{{
          incomingCallData.callerName
        }}</v-list-item-title>
        <v-list-item-subtitle class="subtitle"
          >Incoming call</v-list-item-subtitle
        >
      </v-list-item-content>
      <v-list-item-content>
        <div class="d-flex flex-row ml-5 mr-2">
          <v-tooltip bottom color="white">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                dark
                fab
                depressed
                x-small
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="$emit('call_accepted')"
              >
                <v-icon dark>
                  mdi-phone
                </v-icon>
              </v-btn>
            </template>
            <span class="black--text">Answer Call</span>
          </v-tooltip>
          <v-tooltip bottom color="white">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                dark
                fab
                depressed
                x-small
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="$emit('call_rejected')"
              >
                <v-icon dark>
                  mdi-phone-hangup
                </v-icon>
              </v-btn>
            </template>
            <span class="black--text">Reject Call</span>
          </v-tooltip>
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "IncomingCallNotification",
  props: {
    incomingCallData: {
      type: Object,
      default: function() {
        return {
          callerName: "",
          callerProfilePic: "",
          callerNameIntials: "Avatar",
        };
      },
    },
  },
  data() {
    return {
      assetsBaseURL: process.env.VUE_APP_ASSETS_BASE_URL,
    };
  },
  methods: {
    playAudio() {
      const audioElem = this.$refs.ringtone;

      if (audioElem) {
        try {
          // resetting time to 0
          audioElem.currentTime = 0;
          audioElem.muted = false;
          audioElem.play();
        } catch (error) {
          console.log("ERROR : Audio cannot be played automatically");
        }
      }
    }
  },
  mounted() {
    const ringtoneBtn = this.$refs.ringtoneBtn;

    if (ringtoneBtn) {
      ringtoneBtn.click();
    }
  }
};
</script>

<style lang="scss">
.incoming-call-notification-wrapper {
  background: #011a39;
  border-radius: 15px;
  padding: 10px;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 340px;

  .title {
    color: #80bc40;
  }

  .subtitle {
    color: #bebebe !important;
  }

  .actions-wrapper {
    align-items: center;
  }
}
</style>
