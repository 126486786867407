import Vue from 'vue'
import VueRouter from 'vue-router'
import Registration from '../components/auth/Registration.vue'
import LoginLayout from "@/components/layout/LoginLayout.vue";
// import ResetPassword from "../components/auth/Resetpassword.vue";
import MainLayout from "../components/layout/Layout.vue";
import ChangePassword from "@/components/afterAuth/Changepassword";
import MainLobby from "../components/Mainlobby";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginLayout',
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "Landing",
        component: () => import("../components/auth/Landing"),
        meta: {
          title: "Pinnacle",
        },
      },
      {
        path: '/login',
        name: 'Login',
        meta: {
          title: "Login | Pinnacle",
        },
        component: () => import("../components/auth/Login"),
      },
      {
        path: '/register',
        name: 'Registration',
        meta: {
          title: "Registration | Pinnacle",
        },
        component: Registration
      }

    ]
  },
  {
    path: "/info-desk-chat",
    name: "InfoDeskChat",
    meta: {
      title: "Info Desk Chat",
    },
    component: () => import("../components/infodesk/Chat"),
  },
  {
    path: "/info-desk-chat-admin",
    name: "InfoDeskChatAdmin",
    meta: {
      title: "Info Desk Chat",
    },
    component: () => import("../components/infodesk/ChatReply"),
  },
  {
    path: "/call/video",
    name: "video_call",
    meta: {
      title: "Video Call"
    },
    component: () => import("../views/video_call/VideoCall.vue")
  },
  {
    path: "/app/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Ballroom",
        component: MainLobby,
        meta: {
          title: "Main Lobby",
        },
      },
      {
        path: "session",
        name: "SessionList",
        meta: {
          title: "Session List",
        },
        component: () => import("../components/session/List"),
      },
      {
        path: "session/:id",
        name: "SessionDetails",
        meta: {
          title: "Session Details",
        },
        component: () => import("../components/session/Details"),
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          title: "ChangePassword",
        },
      },
      {
        path: "vendor-hall/:room_id",
        name: "VendorHall",
        meta: {
          title: "VendorHall List",
        },
        component: () => import("../components/vendor-hall/VendorBooths"),
      },
      {
        path: "vendor/:vendor_id",
        name: "VendorBooth",
        meta: {
          title: "Exhibit booth",
        },
        component: () => import("../components/vendor-hall/VendorBooth"),
      },
      {
        path: "exhibitor/directory",
        name: "ExhibitorDirectory",
        meta: {
          title: "ExhibitorDirectory",
        },
        component: () => import("../components/exhibitor/Directory"),
      },
      {
        path: "networking-room",
        name: "NetworkingRoom",
        meta: {
          title: "Networking Room",
        },
        component: () => import("../components/networking-room/List"),
      },
      {
        path: "tote-bag",
        name: "ToteBag",
        meta: {
          title: "Tote Bag",
        },
        component: () => import("../components/tote-bag/List"),
      },
      {
        path: "info-desk",
        name: "InfoDesk",
        meta: {
          title: "Info Desk",
        },
        component: () => import("../components/infodesk/Desk"),
      },
      {
        path: "profile",
        name: "ProfileView",
        meta: {
          title: "Profile View",
        },
        component: () => import("../components/profile/view"),
      }      
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_PUBLIC_PATH,
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/register",
    "/login",
    "/reset-password/" + to.params.id + "/" + to.params.token,
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem("pinnacle_user"));
  // console.log(authRequired,loggedIn)
  document.title = to.meta.title
  if (authRequired && !loggedIn) {
    next("/login");
  } else if (!authRequired && loggedIn) {
    next("/app/");
  } else {
    next();
  }
});
export default router
